<template>
  <v-container class="page_container">
    <div class="post_container_inner">
      <h2 class="post_title">{{ post_title }}</h2>
      <div class="post_img_container">
         <v-img :src="post_img" class="post_img" />
      </div>

<v-container grid-list-xl fluid>
          <v-layout wrap>
            <v-flex
              v-for="img in images"
              :key="img"
              xs12
              md6
            >
              <v-card flat tile>
                <v-img
                  :src="img.url"
                  aspect-ratio="1.7778"
                ></v-img>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>

      <router-link to="/projects" class="back_button">See All Projects</router-link>
    </div>
  </v-container>
</template>
<style>
.post_container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.post_container_inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.post_title {
  padding: 0px 12px;
}
.post_img_container{
  padding: 12px;
}
.back_button {
  padding: 10px 30px;
  border: 1px solid black;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  width: 220px;
  text-align: center;
  transition-duration: 0.2s;
  margin-left: 12px;
}
.back_button:hover {
  background-color: black;
  color: white;
  transition-duration: 0.2s;
}
</style>
<script>
export default {
  data() {
    return {
      post: null,
      post_img: "",
      post_title: null,
      post_content: null,
      images: []
    };
  },
  mounted() {
    let slug = this.$route.params.slug;
    axios
      .get(
        "https://wordpress-310083-949429.cloudwaysapps.com/wp-json/wp/v2/posts?slug=" +
          slug +
          "&_embed"
      )
      .then(
        response => (
          (this.post_title = response.data[0].title.rendered),
          (this.post_content = response.data[0].content.rendered),
          (this.post_img =
            response.data[0]._embedded["wp:featuredmedia"][0].source_url),
            (this.images = response.data[0].acf.gallery)
        )
      );
  }
};
</script>
